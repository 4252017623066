<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Nav with apartment logo</div>
        <div class="bg-gray-200 flex items-center mb-8 px-4">
            <div class="w-1/3 text-left">
                <span v-if="getValue('showMenuButton') !== '0'">
                    <icon name="menu" class="w-4 h-4 inline mr-1 -mt-1"></icon>
                    Menu
                </span>
            </div>
            <div class="w-1/3 text-center px-8 py-2">
                <img :src="getValue('logo')" class="h-8 inline mb-2" v-if="getValue('logo')" />
                <p class="text-xs -mt-1">{{ getValue('tagLine') }}</p>
            </div>
            <div class="text-xs text-right w-1/3">
                <p>{{ getValue('phoneNumber') }}</p>
                <p>{{ getValue('email') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";
    import Widget from "@/mixins/Widget";

    export default {
        components: { Icon },
        mixins: [ Widget ],
    }
</script>